'use client'

import BodyText from '@lyra/core/components/BodyText'
import Card from '@lyra/core/components/Card'
import Countdown from '@lyra/core/components/Countdown'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import PageWithBanner from '@lyra/web/components/layout/PageWithBanner'
import TabsHorizontal from '@lyra/web/components/layout/TabsHorizontal'
import AirdropHeaderPoints from '@lyra/web/containers/airdrop/AirdropHeaderPoints'
import AirdropLevelProgressRow from '@lyra/web/containers/airdrop/AirdropLevelProgressRow'
import LevelsTooltipContent from '@lyra/web/containers/common/LevelsTooltipContent'
import usePoints from '@lyra/web/hooks/usePoints'
import useSelectedTabPage from '@lyra/web/hooks/useSelectedTabPage'
import useTabs from '@lyra/web/hooks/useTabs'
import { formatLevel } from '@lyra/web/utils/points'
import { TabId } from '@lyra/web/utils/tabs'
import { useMemo } from 'react'

type Props = {
  children: React.ReactNode
}

const AirdropLayoutHelper = ({ children }: Props) => {
  const { level, epoch } = usePoints()

  const tab = useTabs()[TabId.Airdrop]
  const selectedPage = useSelectedTabPage()

  const pages = useMemo(() => {
    return tab.pages.map((page) => ({ ...page, isSelected: selectedPage?.id === page.id }))
  }, [tab.pages, selectedPage])

  return (
    <PageWithBanner
      bannerTitle={<AirdropHeaderPoints />}
      bannerSubtitle={
        <BodyText size="lg">
          Round {epoch.round}&nbsp;
          <Countdown color="secondary" endTimestamp={epoch.endTimestamp} showDays />
        </BodyText>
      }
      bannerDesktopRightContent={
        <Card backgroundColor="$appBg">
          <DataWithLabel
            label="Level"
            tooltipContent={level ? <LevelsTooltipContent level={level} epoch={epoch} /> : null}
            value={level ? formatLevel(level) : 'Unknown'}
          />
        </Card>
      }
      bannerBottomRow={<AirdropLevelProgressRow />}
      bannerTabs={<TabsHorizontal isLarge pages={pages} style={{ overflowX: 'auto' }} />}
    >
      {children}
    </PageWithBanner>
  )
}

export default AirdropLayoutHelper
