import Heading from '@lyra/core/components/Heading'
import formatNumber from '@lyra/core/utils/formatNumber'
import useOptimisticPoints from '@lyra/web/hooks/useOptimisticPoints'

const AirdropHeaderPoints = () => {
  const { totalPoints = 0 } = useOptimisticPoints()

  return (
    <Heading size="h2" color={totalPoints ? 'green' : 'primary'}>
      {formatNumber(totalPoints, { dps: 2 })} Points
    </Heading>
  )
}

export default AirdropHeaderPoints
