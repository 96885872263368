import { SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import { useEffect, useMemo, useState } from 'react'

import {
  ACCOUNT_VALUE_POINTS_RATE_HOURLY,
  Points,
  YIELD_POINTS_RATE_HOURLY,
} from '../constants/points'
import { YieldPointsId } from '../constants/yield'
import { getTotalPoints } from '../utils/points'
import { getYieldTokenConfig } from '../utils/yield'
import useOrderbookTimestamp from './useOrderbookTimestamp'
import usePoints from './usePoints'
import useSubaccount from './useSubaccount'
import useYieldPositions from './useYieldPositions'

// 100ms updates
const POLL_INTERVAL_MS = 100

type OptimisticPointsData = {
  totalPoints?: number | undefined
  points?: Points | undefined
}

const getOptimisticPoints = (
  timestamp: number,
  lastUpdateTimestamp: number,
  pointsRatePerInterval: number,
  initPoints: Points
): Points => {
  // Calc how many 100ms intervals since last update
  // - Floored at 0
  const numIntervals = Math.max(Math.floor((timestamp - lastUpdateTimestamp) / POLL_INTERVAL_MS), 0)
  // 1 point per $100 per hour
  const newAccountValuePoints =
    +(initPoints?.account_value ?? '0') + numIntervals * pointsRatePerInterval
  return {
    ...initPoints,
    account_value: newAccountValuePoints,
  }
}

// 1 point per $1 per day
const YIELD_POINTS_RATE_PER_POLL_INTERVAL =
  (YIELD_POINTS_RATE_HOURLY / SECONDS_IN_HOUR / 1_000) * POLL_INTERVAL_MS

export default function useOptimisticPoints(): OptimisticPointsData {
  const { totalAccountValue } = useSubaccount()

  const { data: yieldPositions } = useYieldPositions()

  const accountPointsRatePerInterval = useMemo(
    // Account Value rate per ms * 100ms inetervals
    () =>
      ((totalAccountValue * ACCOUNT_VALUE_POINTS_RATE_HOURLY) / (SECONDS_IN_HOUR * 1_000)) *
      POLL_INTERVAL_MS,
    [totalAccountValue]
  )

  const vaultPointsRatePerInterval = useMemo(() => {
    return yieldPositions
      ? Object.values(yieldPositions).reduce(
          (dict, position) => {
            const value = position.value
            const config = getYieldTokenConfig(position.id)
            const pointsId = config.pointsId
            const pointsRatePerInterval =
              value * YIELD_POINTS_RATE_PER_POLL_INTERVAL * config.pointsMultiplier

            if (!dict[pointsId]) {
              dict[pointsId] = 0
            }

            dict[pointsId] += pointsRatePerInterval
            return dict
          },
          {} as Record<YieldPointsId, number>
        )
      : undefined
  }, [yieldPositions])

  const { getTimestamp: getOrderbookTimestamp } = useOrderbookTimestamp()
  const { points, lastUpdateTimestamp, isMarketMaker } = usePoints()

  const [optimisticPoints, setOptimisticPoints] = useState(
    points && !isMarketMaker
      ? getOptimisticPoints(
          getOrderbookTimestamp(),
          lastUpdateTimestamp,
          accountPointsRatePerInterval,
          points
        )
      : points
  )

  // optimistically tick account per value points every 100ms
  useEffect(() => {
    if (!points || isMarketMaker) {
      return
    }
    const tickAccountValuePoints = () => {
      setOptimisticPoints((currData) =>
        currData
          ? {
              ...currData,
              ...Object.entries(vaultPointsRatePerInterval ?? {}).reduce(
                (dict, [pointsId, pointsRatePerInterval]) => ({
                  ...dict,
                  [pointsId]: currData[pointsId as YieldPointsId] + pointsRatePerInterval,
                }),
                {}
              ),
              account_value: currData.account_value + accountPointsRatePerInterval,
            }
          : undefined
      )
    }
    tickAccountValuePoints()
    const i = setInterval(() => tickAccountValuePoints(), POLL_INTERVAL_MS)
    return () => {
      clearInterval(i)
    }
  }, [
    points,
    lastUpdateTimestamp,
    accountPointsRatePerInterval,
    vaultPointsRatePerInterval,
    getOrderbookTimestamp,
    isMarketMaker,
  ])

  return useMemo(
    () => ({
      totalPoints: optimisticPoints ? getTotalPoints(optimisticPoints) : undefined,
      points: optimisticPoints,
    }),
    [optimisticPoints]
  )
}
