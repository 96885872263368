import { XStack } from '@lyra/core/components'
import VideoText from '@lyra/core/components/VideoText'
import useHover from '@lyra/core/hooks/useHover'
import usePress from '@lyra/core/hooks/usePress'
import { Pathname } from '@lyra/web/constants/pages'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'
import { StackProps } from 'tamagui'

type TabProps = {
  path?: Pathname
  onPress?: () => void
  isSelected?: boolean
  name: string
} & StackProps

type Props = {
  pages: TabProps[]
  isLarge?: boolean
} & StackProps

const Tab = ({ isSelected, name, path, onPress, ...stackProps }: TabProps) => {
  const { prefetch, push } = useRouter()

  useEffect(() => {
    if (path) {
      prefetch(path)
    }
  }, [prefetch, path])

  const { isHovering, handleHoverIn, handleHoverOut } = useHover()
  const { isPressing, handlePressIn, handlePressOut } = usePress()

  return (
    <XStack
      {...stackProps}
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      borderBottomWidth={isSelected ? 1 : 0}
      borderColor={isSelected ? '$invertedBg' : '$hairline'}
      backgroundColor={isPressing ? '$invertedBg' : isHovering ? '$hoverBg' : undefined}
      onPress={onPress ? onPress : path ? () => push(path) : undefined}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      <VideoText
        // size="lg"
        color={isPressing ? 'inverted' : isSelected || isHovering ? 'primary' : 'secondary'}
      >
        {name}
      </VideoText>
    </XStack>
  )
}

export default function TabsHorizontal({ pages, isLarge, ...stackProps }: Props) {
  return (
    <XStack {...stackProps}>
      {pages.map((props) => {
        return (
          <Tab
            key={props.name}
            {...props}
            $desktop={{
              paddingHorizontal: isLarge ? '$4' : '$3',
            }}
            $mobile={{
              paddingHorizontal: '$3',
            }}
            paddingVertical="$2.5"
          />
        )
      })}
    </XStack>
  )
}
