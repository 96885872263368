import { StackProps, useTheme, XStack } from 'tamagui'

type Props = {
  progress: number
  height: number
  isGlowing?: boolean
} & StackProps

export default function LevelProgressBar({ progress, height, isGlowing, ...styleProps }: Props) {
  const percentComplete = Math.max(Math.min(progress, 1), 0) * 100 // bound between 0 and 100
  const gap = 1.5 * height
  const theme = useTheme()
  return (
    <XStack
      height={height}
      // Account for border widths used for shaping
      width={`calc(100% - ${height}px)`}
      {...styleProps}
      gap={gap}
    >
      {progress > 0 ? (
        <XStack
          backgroundColor="$greenPrimary"
          width={`${percentComplete}%`}
          height="100%"
          enterStyle={{
            width: 0,
          }}
          style={{
            boxShadow: isGlowing ? `0px 0px 10px ${theme.greenPrimary?.get()}` : undefined,
          }}
          animation="level-ease"
        >
          <XStack
            height={0}
            width={0}
            borderTopWidth={height}
            borderRightWidth={height}
            borderTopColor="$greenPrimary"
            borderRightColor={progress === 1 ? '$greenPrimary' : 'transparent'}
            overflow="hidden"
            position="absolute"
            right={-height}
            style={{
              filter: isGlowing ? `drop-shadow(0px 0px 5px ${theme.greenPrimary?.val})` : undefined,
            }}
          />
        </XStack>
      ) : null}
      <XStack
        backgroundColor="$hairline"
        width={`${100 - percentComplete}%`}
        enterStyle={{
          width: '100%',
        }}
        height="100%"
        animation="level-ease"
      >
        {progress < 1 ? (
          <XStack
            backgroundColor="$hairline"
            width={`${100 - percentComplete}%`}
            height="100%"
            enterStyle={{
              width: 0,
            }}
            animation="level-ease"
          >
            <XStack
              height={0}
              width={0}
              borderBottomWidth={height}
              borderLeftWidth={height}
              borderBottomColor="$hairline"
              borderLeftColor={progress === 0 ? '$hairline' : 'transparent'}
              overflow="hidden"
              position="absolute"
              left={progress === 0 ? '100%' : -height}
            />
          </XStack>
        ) : null}
      </XStack>
    </XStack>
  )
}
