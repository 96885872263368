import BodyText from '@lyra/core/components/BodyText'
import formatNumber from '@lyra/core/utils/formatNumber'
import LevelProgressBar from '@lyra/web/components/common/LevelProgressBar'
import usePoints from '@lyra/web/hooks/usePoints'
import { formatLevel, getPointsToNextLevel } from '@lyra/web/utils/points'
import { useMemo } from 'react'
import { StackProps, XStack, YStack } from 'tamagui'

import AirdropPointsToNextLevelText from './AirdropPointsToNextLevelText'

type Props = StackProps

export default function AirdropLevelProgressRow({ ...styleProps }: Props) {
  const { epoch, totalPoints = 0 } = usePoints()

  const {
    nextLevel,
    level,
    levelProgress = 0,
  } = useMemo(() => {
    return getPointsToNextLevel(epoch, totalPoints)
  }, [epoch, totalPoints])

  return (
    <YStack gap="$1" {...styleProps}>
      <XStack width="100%" justifyContent="space-between">
        <BodyText color="secondary">{formatLevel(level)}</BodyText>
        {nextLevel ? <BodyText color="secondary">{formatLevel(nextLevel)}</BodyText> : null}
      </XStack>
      <LevelProgressBar isGlowing progress={levelProgress} height={9} />
      {nextLevel ? (
        <XStack width="100%" justifyContent="space-between">
          <AirdropPointsToNextLevelText />
          <BodyText color="secondary">{formatNumber(nextLevel.cutoff)}</BodyText>
        </XStack>
      ) : (
        <BodyText color="secondary">You reached the highest level</BodyText>
      )}
    </YStack>
  )
}
